import React, { useEffect, useState } from "react";
import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import CustomSlider from "../../../components/CustomSlider/CustomSlider";
import ToolTip from "../../../components/ToolTip/ToolTip";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { BiCurrentLocation } from "react-icons/bi";

import { useSession } from "../../../contexts/SessionContext";
import { cuisines } from "../../../utils/cuisines";
import {
  apiUpdateSettings,
  apiGetCoordinates,
  apiGetSession,
  apiLeaveSession,
} from "../../../utils/apiCalls";
import { getLocation, getParsedLocation } from "../../../utils/location";
import { getUseableLocation, checkIfZipcode } from "../../../utils/location";
import {
  startUpdateSettingsListener,
  startGetSessionListener,
  startLeaveSessionListener,
} from "../../../utils/listeners";
import { getRandomPlace } from "../../../utils/locationsList";

const SettingsModalPopup = ({
  isSettingsModalOpen,
  setIsSettingsModalOpen,
  setIsLoadingModalOpen,
  setLoadingModalText,
}) => {
  const {
    websocket,
    session,
    setPlaces,
    resetSession,
    generateResponseId,
    checkResponseId,
    location,
    getDeviceId,
    setUser,
    setLocalConfig,
    setSession,
  } = useSession();
  const navigate = useNavigate();
  const [inputLocation, setInputLocation] = useState("");
  const [inputDistance, setInputDistance] = useState(50);
  const [inputCuisines, setInputCuisines] = useState([]);
  const [locationInputDisabled, setLocationInputDisabled] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");

  useEffect(() => {
    if (session) {
      setInputLocation(
        `${session?.location?.latitude || ""},${
          session?.location?.longitude || ""
        }`
      );
      setInputDistance(Number(session?.distance || 50));
      setInputCuisines(session?.cuisines || []);
    }
  }, [session]);

  useEffect(() => {
    if (checkIfZipcode(inputLocation)) {
      apiGetCoordinates({
        websocket,
        responseId: generateResponseId(),
        zipcode: 39470,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputLocation]);

  return (
    <>
      <ModalPopup
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen((now) => !now)}
        style={{ width: "300px", height: "200px" }}
      >
        {session && (
          <div className="SwipeScreen-SettingsModalPopup-content-wrapper">
            <div>
              <ToolTip text="Enter zipcode, coordinates or use location button">
                <CustomInput
                  label="Search where?"
                  value={inputLocation}
                  disabled={locationInputDisabled}
                  onChange={(value) => {
                    setInputLocation(value);
                  }}
                  inputStyle={{ textAlign: "center" }}
                />
              </ToolTip>
              <ToolTip text="Get Location">
                <BiCurrentLocation
                  className="SwipeScreen-get-location-icon"
                  onClick={() => {
                    setLoadingModalText("Getting Location...");
                    setIsLoadingModalOpen(true);
                    setLocationInputDisabled(true);
                    setInputLocation("Getting Location...");
                    getLocation()
                      .then((coords) => {
                        setLoadingModalText = "";
                        setIsLoadingModalOpen(false);
                        const parsedLocation = getParsedLocation(
                          JSON.stringify(coords)
                        );
                        if (parsedLocation) {
                          setLocalConfig("location_accesss", "true");
                          setLocalConfig("location_reason", "");
                          setInputLocation(parsedLocation);
                        } else {
                          setAlertModalText("Failed to update location.");
                          setIsSettingsModalOpen(false);
                          setIsAlertModalOpen(true);

                          //reset location input to previous state or new location if no location set
                          setLocationInputDisabled(false);
                          if (location)
                            setInputLocation(getParsedLocation(location));
                          else
                            setInputLocation(
                              getParsedLocation(getRandomPlace())
                            );
                        }
                        setLocationInputDisabled(false);
                      })
                      .catch((error) => {
                        setLoadingModalText = "";
                        setIsLoadingModalOpen(false);
                        setAlertModalText("Failed to update location.");
                        setIsSettingsModalOpen(false);
                        setIsAlertModalOpen(true);

                        //reset location input to previous state or new location if no location set
                        setLocationInputDisabled(false);
                        if (location)
                          setInputLocation(getParsedLocation(location));
                        else
                          setInputLocation(getParsedLocation(getRandomPlace()));
                      });
                  }}
                />
              </ToolTip>
            </div>

            <CustomSlider
              value={inputDistance}
              onChange={setInputDistance}
              label="How Far?"
              units="Miles"
              min={0}
              max={100}
            />
            <CustomDropdown
              label="What’s your mood?"
              options={cuisines}
              selectedValues={inputCuisines}
              onChange={setInputCuisines}
              
            />
          </div>
        )}
        {!session && (
          <div style={{ margin: "10px 0" }}>
            <LoadingSpinner isLoading={true} height={50} width={50} />
          </div>
        )}
        <div className="SwipeScreen-SettingsModalPopup-button-wrapper">
          <CustomButton
            label="Save"
            variant="primary"
            size="med"
            fontWeight={700}
            fontSize="20px"
            style={{ width: "100%", textAlign: "center" }}
            onClick={() => {
              const responseId = generateResponseId();
              setLoadingModalText("Updating settings...");
              setIsLoadingModalOpen(true);
              setTimeout(() => {
                setIsLoadingModalOpen(false);
                setLoadingModalText("");
                setAlertModalText("Failed to update settings.");
                setIsAlertModalOpen(true);
              }, 10000);
              startUpdateSettingsListener({
                checkResponseId,
                responseId,
                websocket,
                setPlaces,
                location,
                sessionId: session?.sessionId,
                deviceId: getDeviceId(),
                resetSession,
                navigate,
              })
                .then((data) => {
                  if (data && data?.success === true) {
                    const responseId = generateResponseId();
                    setLoadingModalText("Saved...");
                    setTimeout(
                      () => setLoadingModalText("Refreshing Session..."),
                      1000
                    );
                    startGetSessionListener({
                      checkResponseId,
                      responseId,
                      setUser,
                      setLocalConfig,
                      setSession,
                      websocket,
                    })
                      .then((data) => {
                        navigate(0);
                      })
                      .catch((error) => {
                        navigate(0);
                      });
                    apiGetSession({
                      websocket,
                      responseId,
                      deviceId: getDeviceId(),
                      sessionId: session?.sessionId,
                    });
                  }
                })
                .catch((error) => {});

              apiUpdateSettings({
                websocket,
                responseId,
                deviceId: getDeviceId(),
                location: getUseableLocation(inputLocation),
                cuisines: inputCuisines,
                distance: inputDistance,
                sessionId: session?.sessionId,
              });
              setIsSettingsModalOpen(false);
            }}
          />
          <CustomButton
            label="Cancel"
            variant="primary"
            size="med"
            fontWeight={700}
            fontSize="20px"
            style={{ width: "100%", textAlign: "center", margin: "0 10px" }}
            onClick={() => setIsSettingsModalOpen((now) => !now)}
          />
          <CustomButton
            label="Leave"
            variant="primary"
            size="med"
            fontWeight={700}
            fontSize="20px"
            style={{ width: "100%", textAlign: "center" }}
            onClick={() => {
              const responseId = generateResponseId();
              setLoadingModalText("Looking for the exit...");
              setIsSettingsModalOpen(false);
              setIsLoadingModalOpen(true);
              startLeaveSessionListener({
                checkResponseId,
                responseId,
                setUser,
                setLocalConfig,
                setSession,
                websocket,
              })
                .then((data) => {
                  resetSession();
                  navigate("/");
                })
                .catch((error) => {
                  resetSession();
                  navigate("/");
                });
              apiLeaveSession({
                websocket,
                responseId,
                deviceId: getDeviceId(),
                sessionId: session?.sessionId,
              });
            }}
          />
        </div>
      </ModalPopup>
      <ModalPopup
        isOpen={isAlertModalOpen}
        onClose={() => setIsAlertModalOpen((now) => !now)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "'Lato', sans-serif",
          }}
        >
          <div
            style={{
              padding: "30px 0 0 0",
              fontSize: "22px",
              textAlign: "center",
              fontFamily: "'Lato', sans-serif",
            }}
          >
            {alertModalText}
          </div>
          <div
            style={{
              padding: "10px 0 20px 0",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            Please try again.
          </div>
          <CustomButton
            label="Close"
            variant="primary"
            size="med"
            fontWeight={700}
            fontSize="20px"
            style={{ width: "100%", textAlign: "center", margin: "0 10px" }}
            onClick={() => {
              setIsAlertModalOpen(false);
              setIsSettingsModalOpen(true);
            }}
          />
        </div>
      </ModalPopup>
    </>
  );
};

export default SettingsModalPopup;
