class WebSocketHandler {
  constructor({ url }) {
    this.url = url;
    this.socket = null;
    this.listeners = {
      message:
        process.env.NODE_ENV === "development"
          ? [(event) => console.log("DEV::WEBSOCKET:RECEIVED: ", event)]
          : [],
      open:
        process.env.NODE_ENV === "development"
          ? [(event) => console.log("DEV::WEBSOCKET:RECEIVED:OPEN ", event)]
          : [],
      close:
        process.env.NODE_ENV === "development"
          ? [(event) => console.log("DEV::WEBSOCKET:RECEIVED:CLOSE ", event)]
          : [],
      error:
        process.env.NODE_ENV === "development"
          ? [(event) => console.log("DEV::WEBSOCKET:RECEIVED:ERROR ", event)]
          : [],
    };
  }

  // Internal handlers that call registered listeners
  _handleMessage(event) {
    this._notifyListeners("message", event.data);
  }

  _handleOpen(event) {
    this._notifyListeners("open", event);
  }

  _handleClose(event) {
    this._notifyListeners("close", event);
  }

  _handleError(event) {
    this._notifyListeners("error", event);
  }

  //connect to websocket
  connect({ params }) {
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      let url = this.url;
      if (params) {
        url = url + params;
      }
      this.socket = new WebSocket(url);
      this.socket.addEventListener("message", (event) =>
        this._handleMessage(event)
      );
      this.socket.addEventListener("open", (event) => this._handleOpen(event));
      this.socket.addEventListener("close", (event) =>
        this._handleClose(event)
      );
      this.socket.addEventListener("error", (event) =>
        this._handleError(event)
      );
    }
    return this.socket;
  }

  //check readyState
  isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  // Register and deregister listeners
  addEventListener(eventType, callback) {
    if (!this.listeners[eventType]) {
      const error = new Error(
        `Error 420: Unsupported event type: ${eventType}`
      );
      throw error;
    }
    this.listeners[eventType].push(callback);
    if (process.env.NODE_ENV === "development" && parseInt(process.env.REACT_APP_DEV_VERBOSE_LEVEL) >= 3) {
      console.log(`DEV::EVENTS:ADD_LISTENER: ${eventType} @ ${callback}`);
      if (parseInt(process.env.REACT_APP_DEV_VERBOSE_LEVEL) >= 5) console.log(`DEV::EVENTS:LISTENERS:${eventType}:${this.listeners[eventType]?.length}: ${this.listeners[eventType]}`);
    }
  }

  removeEventListener(eventType, callback) {
    if (!this.listeners[eventType]) {
      const error = new Error(
        `Error 420: Unsupported event type: ${eventType}`
      );
      throw error;
    }
    this.listeners[eventType] = this.listeners[eventType].filter(
      (cb) => cb !== callback
    );
    if (process.env.NODE_ENV === "development" && parseInt(process.env.REACT_APP_DEV_VERBOSE_LEVEL) >= 3) {
      console.log(`DEV::EVENTS:REMOVE_LISTENER: ${eventType} @ ${callback}`);
      if (parseInt(process.env.REACT_APP_DEV_VERBOSE_LEVEL) >= 5) console.log(`DEV::EVENTS:LISTENERS:${eventType}:${this.listeners[eventType]?.length}: ${this.listeners[eventType]}`);
    }
  }

  // Notify all registered listeners for an event
  _notifyListeners(eventType, payload) {
    if (!this.listeners[eventType]) return;
    this.listeners[eventType].forEach((callback) => callback(payload));
  }

  // Method to send a message
  sendMessage(message) {
    if (this.socket?.readyState === WebSocket.OPEN) {
      if (process.env.NODE_ENV === "development")
        console.log("DEV::WEBSOCKET:SENT:",this.url,"::", message);
      this.socket.send(JSON.stringify(message));
    } else {
      throw new Error(
        `Error 420: Cannot send message: WebSocket is not open. Please try again.`
      );
    }
  }

  // Method to close the socket
  close() {
    if (this?.socket?.close) return this.socket.close();
  }
}

export default WebSocketHandler;
