//Get an existing session by a session ID
export const apiGetSession = ({
  websocket,
  responseId,
  deviceId,
  sessionId,
}) => {
  websocket.sendMessage({
    action: "getSession",
    responseId,
    deviceId,
    sessionId,
  });
};

//join a session
export const apiJoinSession = ({
  websocket,
  responseId,
  deviceId,
  sessionId,
}) => {
  websocket.sendMessage({
    action: "joinSession",
    responseId,
    deviceId,
    sessionId,
  });
};

//Leave a session
export const apiLeaveSession = ({
  websocket,
  responseId,
  deviceId,
  sessionId,
}) => {
  websocket.sendMessage({
    action: "leaveSession",
    responseId,
    deviceId,
    sessionId,
  });
};

//Start a new session
export const apiStartSession = ({
  websocket,
  deviceId,
  responseId,
  location,
}) => {
  websocket.sendMessage({
    action: "startSession",
    deviceId,
    responseId,
    location,
  });
};

//get the places for a specific session
export const apiGetPlaces = ({
  websocket,
  responseId,
  sessionId,
  deviceId,
}) => {
  websocket.sendMessage({
    action: "getPlaces",
    responseId,
    sessionId,
    deviceId,
  });
};

//get the places for a specific session
export const apiGetNextPlaces = ({
  websocket,
  responseId,
  sessionId,
  deviceId,
  pageToken,
}) => {
  websocket.sendMessage({
    action: "getNextPlaces",
    responseId,
    sessionId,
    deviceId,
    pageToken,
  });
};

//update all settings at once
export const apiUpdateSettings = ({
  websocket,
  responseId,
  location,
  cuisines,
  distance,
  sessionId,
  deviceId
}) => {
  websocket.sendMessage({
    action: "updateSettings",
    responseId,
    location,
    cuisines,
    distance,
    sessionId,
    deviceId
  });
};

//convert a zipcode to coordinates
export const apiGetCoordinates = ({ zipcode, websocket, responseId }) => {
  websocket.sendMessage({
    action: "getCoordinates",
    responseId,
    zipcode,
  });
};

export const apiSwipeRight = ({
  websocket,
  responseId,
  sessionId,
  deviceId,
  placeId,
}) => {
  websocket.sendMessage({
    action: "swipeRight",
    responseId,
    sessionId,
    deviceId,
    placeId,
  });
};

export const apiSwipeLeft = ({
  websocket,
  responseId,
  sessionId,
  deviceId,
  placeId,
}) => {
  websocket.sendMessage({
    action: "swipeLeft",
    responseId,
    sessionId,
    deviceId,
    placeId,
  });
};

export const apiResetSwipes = ({
  websocket,
  responseId,
  sessionId,
  deviceId,
}) => {
  websocket.sendMessage({
    action: "resetSwipes",
    responseId,
    sessionId,
    deviceId,
  });
};

export const apiSendInquiry = ({
  websocket,
  responseId,
  deviceId,
  name,
  email,
  message,
}) => {
  websocket.sendMessage({
    action: "inquiry",
    responseId,
    deviceId,
    name,
    email,
    message,
  });
};

export const apiReportError = ({
  websocket,
  responseId,
  deviceId,
  errorReport
}) => {
  websocket.sendMessage({
    action: "reportError",
    responseId,
    deviceId,
    errorReport
  });
};
