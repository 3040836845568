export const startSessionListener = ({
  checkResponseId,
  responseId,
  setUser,
  setLocalConfig,
  setSession,
  websocket,
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
      //startSession
      checkResponseId({ event, responseId }) //hanlde response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          if (data) {
            if (data.user) {
              setUser(data.user);
              setLocalConfig("user", JSON.stringify(data.user));
            }
            if (data.session) {
              setLocalConfig("session", JSON.stringify(data.session));
              setSession(data.session);
            }
            resolve(data);
            return data;
          }
        })
        .catch((error) => {
          console.error("Error checking response", event);
          reject(error);
          return false;
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startGetSessionListener = ({
  checkResponseId,
  responseId,
  setUser,
  setLocalConfig,
  setSession,
  websocket,
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
       //getSession
      checkResponseId({ event, responseId }) //handle response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          if (data) {
            if (data.user) {
              setUser(data.user);
              setLocalConfig("user", JSON.stringify(data.user));
            }
            if (data.session) {
              setLocalConfig("session", JSON.stringify(data.session));
              setSession(data.session);
            }
            resolve(data);
            return data;
          }
        })
        .catch((error) => {
          console.error("Error checking response", event);
          reject(error);
          return false;
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startJoinSessionListener = ({
  checkResponseId,
  responseId,
  setUser,
  setLocalConfig,
  setSession,
  websocket,
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
      checkResponseId({ event, responseId }) //handle response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          if (data) {
            if (data.error) {
              reject(data)
              return data;
            }
            if (data.user) {
              setUser(data.user);
              setLocalConfig("user", JSON.stringify(data.user));
            }
            if (data.session) {
              setSession(data.session);
              setLocalConfig("session", JSON.stringify(data.session));
            }
            resolve(data);
            return data;
          }
        })
        .catch((error) => {
          console.error("Error checking response", event);
          reject(error);
          return false;
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startGetPlacesListener = ({
  checkResponseId,
  responseId,
  websocket,
  navigate,
  resetSession,
  setPlaces,
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
      checkResponseId({ event, responseId }) //handle response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          if (data) {
            if (data.session === null) {
              //reset session and kick user back to home screens
              resetSession();
              navigate("/");
            }
            if (data.places) setPlaces(data.places);
          }
        })
        .catch((error) => {
          console.error("Error checking response", error);
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startGetNextPlacesListener = ({
  checkResponseId,
  responseId,
  websocket,
  setPlaces,
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
      checkResponseId({ event, responseId }) //hanlde response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          if (data) {
            if (data.places) setPlaces((places) => [...places, ...data.places]);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
          console.error("Error checking response", error);
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startUpdateSettingsListener = ({
  checkResponseId,
  responseId,
  websocket
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
      checkResponseId({ event, responseId }) //hanlde response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          resolve(data);
        })
        .catch((error) => {
          console.error("Error checking response", error);
          reject(error);
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startLeaveSessionListener = ({
    checkResponseId,
    responseId,
    setUser,
    setLocalConfig,
    setSession,
    websocket,
  }) => {
    return new Promise((resolve, reject) => {
      const responseListener = (event) => {
        checkResponseId({ event, responseId }) //handle response
          .then((data) => {
            websocket.removeEventListener("message", responseListener);
            if (data) {
              if (data.user) {
                setUser(data.user);
                setLocalConfig("user", JSON.stringify(data.user));
              }
              if (data.session) {
                setSession(data.session);
                setLocalConfig("session", JSON.stringify(data.session));
              }
              resolve(data);
              return data;
            }
          })
          .catch((error) => {
            console.error("Error checking response", event);
            reject(error);
            return false;
          });
      };
      websocket.addEventListener("message", responseListener);
    });
  };

export const startSwipeRightListener = ({
  checkResponseId,
  responseId,
  websocket,
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
      checkResponseId({ event, responseId }) //hanlde response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          resolve(data);
        })
        .catch((error) => {
          console.error("Error checking response", error);
          reject(error);
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startSwipeLeftListener = ({
  checkResponseId,
  responseId,
  websocket,
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
      checkResponseId({ event, responseId }) //hanlde response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          resolve(data);
        })
        .catch((error) => {
          console.error("Error checking response", error);
          reject(error);
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startResetSwipesListener = ({
  checkResponseId,
  responseId,
  websocket,
}) => {
  return new Promise((resolve, reject) => {
    //create timeout here
      //remove Event Listener
      //Reject promise and handle failure at function call
    const responseListener = (event) => {
      checkResponseId({ event, responseId }) //hanlde response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          resolve(data);
        })
        .catch((error) => {
          console.error("Error checking response", error);
          reject(error);
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};

export const startSendInquiryListener = ({
  checkResponseId,
  responseId,
  websocket,
}) => {
  return new Promise((resolve, reject) => {
    const responseListener = (event) => {
      checkResponseId({ event, responseId }) //hanlde response
        .then((data) => {
          websocket.removeEventListener("message", responseListener);
          resolve(data);
        })
        .catch((error) => {
          console.error("Error checking response", error);
          reject(error);
        });
    };
    websocket.addEventListener("message", responseListener);
  });
};
