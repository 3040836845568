const noSwipeOneLiners = [
    "Hungry? Just take a wild guess, will ya?",
    "All these choices, yet here you are!",
    "Too many restaurants, too little time!",
    "Decision paralysis? We're here to help... sort of.",
    "If you don't pick soon, your stomach will!",
    "Swipe left, swipe right, just don’t swipe away your appetite!",
    "Indecision is the thief of meal time!",
    "Don’t starve over a swipe!",
    "Remember, the best restaurant is the one you finally choose!",
    "Still undecided? There's always a coin flip!",
    "Swipe or starve – your choice!",
    "You've seen them all, now make the call!",
    "This isn't a dating app, just pick a place!",
    "Too many swipes, not enough bites!",
    "Dinner waits for no one. Neither should you!",
    "The only bad decision is no decision at all.",
    "The clock is ticking, and so is your hunger!",
    "Your taste buds are begging you to commit!",
    "Stop swiping; start dining!",
    "If food were free, would you still hesitate?",
    "The only thing left to swipe is your credit card.",
    "It's food, not a lifelong commitment!",
    "Don't let your appetite outlast your battery life!",
    "The hunger games are real, and you're the contestant!",
    "Dinner doesn't pick itself. Unfortunately.",
    "Swipe less, savor more.",
    "Don't let hunger make the choice for you!"
];


const loadPlacesOneLiners = [
    "Fetching tasty spots...",
    "Uncovering culinary gems...",
    "Loading flavor destinations...",
    "Finding your next meal...",
    "Seeking out delicious eats...",
    "Hunting for hunger fixes...",
    "Scouting for sustenance...",
    "Discovering dining delights...",
    "Spotlighting savory selections...",
    "Rounding up great eats...",
    "Navigating the food map...",
    "Charting a course for taste...",
    "Zeroing in on flavor...",
    "Picking out palate pleasers...",
    "Cruising through cuisines...",
  ];


export const getNoSwipesOneliner = () => {
    const randomIndex = Math.floor(Math.random() * noSwipeOneLiners.length);
    return noSwipeOneLiners[randomIndex];
}

export const getLoadPlacesOneliner = () => {
    const randomIndex = Math.floor(Math.random() * loadPlacesOneLiners.length);
    return loadPlacesOneLiners[randomIndex];
}
