import React, { useState, useEffect } from "react";
import "./AlertBanner.css";
import { useSession } from "../../contexts/SessionContext";

const AlertBanner = () => {
  const { alertBannerMsg, setAlertBannerMsg } = useSession();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (alertBannerMsg) {
      setShow(true);

      // Set a timer to hide the banner after 5 seconds
      const timer = setTimeout(() => {
        setShow(false);
      }, 3000);

      // Clean up the timer when the component is unmounted or message changes
      return () => clearTimeout(timer);
    }
  }, [alertBannerMsg]);

  if (!alertBannerMsg) {
    return null; // Don't render the component if there is no message
  }

  return (
    <div
      className={`AlertBanner-container ${show ? "show" : ""}`}
      onTransitionEnd={() => {
        if (!show) setAlertBannerMsg(""); // Clear the message once the animation is complete and the banner slides out
      }}
    >
      {alertBannerMsg}
    </div>
  );
};

export default AlertBanner;
