import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import "./SwipeScreen.css";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../contexts/SessionContext";
import { IoSettingsSharp, IoShareSocial } from "react-icons/io5";

import Swipeable from "../../components/Swipeable/Swipeable";
import AlertModalPopup from "./ModalPopups/AlertModalPopup";
import AlertBanner from "../../components/AlertBanner/AlertBanner";
import LocationRequestModalPopup from "./ModalPopups/LocationRequestModalPopup";
import SettingsModalPopup from "./ModalPopups/SettingsModalPopup";
import ToolTip from "../../components/ToolTip/ToolTip";
import ShareModal from "../../components/ShareModal/ShareModal";
import TutorialOverlay from "../../components/TutorialOverlay/TutorialOverlay";
import {
  startSessionListener,
  startJoinSessionListener,
  startGetPlacesListener,
} from "../../utils/listeners";
import {
  apiStartSession,
  apiJoinSession,
  apiGetPlaces,
} from "../../utils/apiCalls";
import LoadingModalPopup from "./ModalPopups/LoadingModalPopup";
import FullscreenToggleButton from "../../components/FullscreenToggleButton/FullscreenToggleButton";
import { getRandomPlace } from "../../utils/locationsList";
import { getLocation } from "../../utils/location";
import tutorialSteps from "./steps";

const params = new URLSearchParams(window.location.search);
const joinSession = params.get("joinSession");
const joinSessionId = params.get("sessionId");

const SwipeScreen = () => {
  const navigate = useNavigate();
  const {
    getLocalConfig,
    setLocalConfig,
    isConnected,
    location,
    setLocation,
    getDeviceId,
    websocket,
    generateResponseId,
    checkResponseId,
    setSession,
    resetSession,
    setUser,
    session,
    getLocalSession,
    places,
    setPlaces,
    alertBannerMsg,
    setAlertBannerMsg
  } = useSession();
  const isApiCallSent = useRef(false);

  const [locationModalIsOpen, setLocationModalIsOpen] = useState(false);
  const [locationModalSpinnerSpin, setLocationModalSpinnerSpin] =
    useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");
  const [AlertModalComponents, setAlertModalComponents] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState();
  const [showTutorial, setShowTutorial] = useState(false);
  const [loadingModalText, setLoadingModalText] = useState("");
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  //check to see if the tutorial is active
  useEffect(() => {
    const isActive = getLocalConfig("tutorialActive");
    if (isActive === "true") {
      setShowTutorial(true);
      if (process.env.NODE_ENV !== "development") {
        ReactGA.event({
          category: "TUTORIAL",
          action: "SWIPEABLE_TUTORIAL_STARTED",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //check if user has been asked in the past for location, ask to enable location or get location if available
  useEffect(() => {
    if (!location) {
      if (
        getLocalConfig("location_accesss") !== "true" &&
        getLocalConfig("location_reason") !== "denied"
      ) {
        setLocationModalIsOpen(true);
      } else if (
        getLocalConfig("location_access") !== "true" &&
        getLocalConfig("location_reason") === "denied"
      ) {
        setAlertModalText(
          "Location previously denied.<br>A generic location will be used."
        );
        setAlertModalIsOpen(true);
        setLocation(getRandomPlace());
      } else {
        getLocation()
        .then((location) => {
          if (location) {
            setLocationModalIsOpen(false);
            setLocationModalSpinnerSpin(false);
            setLocalConfig("location_accesss", "true");
            setLocation(location);
          } else {
            setAlertModalText(
              "Error getting location. <br>You may try again in the settings."
            );
            setAlertModalIsOpen(true);
            setLocation(getRandomPlace());
          }
        })
        .catch((error) => {
          setLocalConfig("location_accesss", false);
          setLocalConfig("location_reason", "error");
          setAlertModalText(
            "Error getting location. <br>You may try again in the settings."
          );
          setAlertModalIsOpen(true);
          setLocation(getRandomPlace());
          if (process.env.NODE_ENV === "development") {
            ReactGA.event({
              category: "USER_LOCATION",
              action: "ERROR_LOCATION",
              label: error,
            });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //get places or start a new session and get places once session has started
  useEffect(() => {
    if (location && isConnected) {
      const currentSession = session || getLocalSession(); //get whichever is not falsey
      //if user joined from an invite link
      if (joinSession && currentSession?.sessionId !== joinSessionId) {
        console.log("ran 2")
        if (process.env.NODE_ENV !== "development") {
          ReactGA.event({
            category: "SWIPEABLE",
            action: "SWIPEABLE_USER_JOIN",
          });
        }
        const responseId = generateResponseId();
        const deviceId = getDeviceId();
        const sessionId = joinSessionId;

        startJoinSessionListener({
          checkResponseId,
          responseId,
          setUser,
          setLocalConfig,
          setSession,
          websocket,
        })
          .then((data) => {
            const responseId = generateResponseId();
            const deviceId = getDeviceId();
            const sessionId = data.session.sessionId;
            //get the places for the session once state updated
            apiGetPlaces({
              websocket,
              responseId,
              sessionId,
              deviceId,
            });
            startGetPlacesListener({
              checkResponseId,
              responseId,
              websocket,
              navigate,
              resetSession,
              setPlaces,
            });
          })
          .catch((error) => { 
            setAlertBannerMsg(error.error);
            navigate("/")
          });

        apiJoinSession({
          websocket,
          responseId,
          deviceId,
          sessionId,
        });
      } else if (!currentSession && !joinSession && !isApiCallSent.current) {
        isApiCallSent.current = true;
        if (process.env.NODE_ENV !== "development") {
          ReactGA.event({
            category: "SWIPEABLE",
            action: "SWIPEABLE_LOCATION_SEARCHED",
            label: JSON.stringify(location),
          });
        }
        //no session found
        const responseId = generateResponseId();
        const deviceId = getDeviceId();
        //set start session listener and set session/user once response received
        startSessionListener({
          checkResponseId,
          responseId,
          setUser,
          setLocalConfig,
          setSession,
          websocket,
        })
          .then((data) => {
            if (data) {
              //get the places for the session once state updated
              apiGetPlaces({
                websocket,
                responseId,
                location,
                sessionId: data?.session?.sessionId,
                deviceId,
              });
              startGetPlacesListener({
                checkResponseId,
                responseId,
                websocket,
                navigate,
                resetSession,
                setPlaces,
              });
            }
          })
          .catch((e) => {
            const error = new Error(
              "Error 420: Failed to start new session. Please try again."
            );
            error.location = location || null;
            error.deviceId = deviceId || null;
            error.responseId = responseId || null;
            error.session = session || null;
            throw error;
          });
        //start a new session here after location has been obtained and socket is connected
        apiStartSession({
          websocket,
          deviceId,
          responseId,
          location,
        });
      } else if (!isApiCallSent.current) {
        isApiCallSent.current = true;
        const responseId = generateResponseId();
        const deviceId = getDeviceId();
        const sessionId = currentSession?.sessionId;
        setSession(currentSession);

        startGetPlacesListener({
          checkResponseId,
          responseId,
          websocket,
          navigate,
          resetSession,
          setPlaces,
        });

        //get the places for the session once state updated
        apiGetPlaces({
          websocket,
          responseId,
          sessionId,
          deviceId,
        });
      }
    }

    return () => {
      setPlaces([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isConnected]);

  return (
    <div className="SwipeScreen-container">
      {showTutorial && (
        <TutorialOverlay
          toggleClose={() => {
            setShowTutorial((x) => !x);
            setLocalConfig("tutorialActive", false); //reset state
          }}
          steps={tutorialSteps}
          endFunction={() => {
            if (process.env.NODE_ENV !== "development") {
              ReactGA.event({
                category: "TUTORIAL",
                action: "SWIPEABLE_TUTORIAL_COMPLETED",
              });
            }
            setShowTutorial((visible) => !visible);
            setLocalConfig("tutorialActive", false); //reset state
          }}
        />
      )}
      <Swipeable
        places={places}
        like={() => {}}
        dislike={() => {}}
        wsHandlerRef={null}
        setIsLoadingModalOpen={setIsLoadingModalOpen}
        setLoadingModalText={setLoadingModalText}
      />
      <div className="SwipeScreen-icon-container">
        <button
          className="SwipeScreen-share-button"
          onClick={() => {
            setIsShareModalOpen(!isShareModalOpen);
          }}
        >
          <IoShareSocial className="SwipeScreen-share-icon tutorial-social-icon" />
        </button>
        <ToolTip text="Users in session" bottom={true}>
          <div className="SwipeScreen-user-count tutorial-user-count-icon">
            {session?.users ? session.users.length : "1"}
          </div>
        </ToolTip>
        <FullscreenToggleButton />
        <button
          className="SwipeScreen-settings-button"
          onClick={() => {
            setIsSettingsModalOpen(!isSettingsModalOpen);
          }}
        >
          <IoSettingsSharp className="SwipeScreen-settings-icon tutorial-settings-icon" />
        </button>
      </div>
      <AlertModalPopup //Alert Modal for display text in alert
        alertModalIsOpen={alertModalIsOpen}
        alertModalText={alertModalText}
        setAlertModalIsOpen={setAlertModalIsOpen}
        AlertModalComponents={AlertModalComponents}
      />
      <LocationRequestModalPopup //Modal to request for access to users location
        locationModalIsOpen={locationModalIsOpen}
        setLocationModalIsOpen={setLocationModalIsOpen}
        locationModalSpinnerSpin={locationModalSpinnerSpin}
        setLocationModalSpinnerSpin={setLocationModalSpinnerSpin}
        setAlertModalText={setAlertModalText}
        setAlertModalIsOpen={setAlertModalIsOpen}
      />
      <SettingsModalPopup
        isSettingsModalOpen={isSettingsModalOpen}
        setIsSettingsModalOpen={setIsSettingsModalOpen}
        setIsLoadingModalOpen={setIsLoadingModalOpen}
        setLoadingModalText={setLoadingModalText}
      />
      <LoadingModalPopup
        modalText={loadingModalText}
        isOpen={isLoadingModalOpen}
        onClose={setIsLoadingModalOpen}
      />
      {isShareModalOpen && (
        <ShareModal
          session={session}
          setAlertModalText={setAlertModalText}
          setAlertModalIsOpen={setAlertModalIsOpen}
          setAlertModalComponents={setAlertModalComponents}
          AlertModalComponents={AlertModalComponents}
          setIsOpen={setIsShareModalOpen}
        />
      )}
        <AlertBanner />
    </div>
  );
};

export default SwipeScreen;
